@font-face {
  font-family: Aka-AcidGR-Fifindrel;
  src: url("Aka-AcidGR-Fifindrel.fa285661.woff2") format("woff2"), url("Aka-AcidGR-Fifindrel.c1f0d639.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: Aka-AcidGR-CuttingEdge;
  src: url("Aka-AcidGR-CuttingEdge.862bd8eb.woff2") format("woff2"), url("Aka-AcidGR-CuttingEdge.fd8508b5.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: Aka-Acid-BrieLight;
  src: url("Aka-Acid-BrieLight.a2e5b166.woff2") format("woff2"), url("Aka-Acid-BrieLight.725b50b4.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

html {
  font-family: Aka-Acid-BrieLight, serif;
}

::selection {
  color: #fff;
  background: pink;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.colorful-top {
  background: linear-gradient(#fff0 0%, #f2f2f24a 49.55%, #fff 100%), linear-gradient(90deg, #f9ddf1 0%, #e5e1ff 18.87%, #daedff 36.29%, #e2f4e3 51.66%, #ebf5d8 67.03%, #faf2da 80.04%, #fbe5d8 98.28%);
}

.colorful-bottom {
  background: linear-gradient(0deg, #fff0 0%, #f2f2f24a 49.55%, #fff 100%), linear-gradient(90deg, #fbe5d8 0%, #faf2da 9.71%, #ebf5d8 25.13%, #e2f4e3 33.61%, #daedff 48.48%, #e5e1ff 66.68%, #f9ddf1 80.72%);
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.z-0 {
  z-index: 0;
}

.z-20 {
  z-index: 20;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-\[15vh\] {
  margin-top: 15vh;
}

.mt-\[10vh\] {
  margin-top: 10vh;
}

.mt-10 {
  margin-top: 2.5rem;
}

.flex {
  display: flex;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-full {
  height: 100%;
}

.h-1\/2 {
  height: 50%;
}

.h-screen {
  height: 100vh;
}

.h-\[20vh\] {
  height: 20vh;
}

.h-\[10vh\] {
  height: 10vh;
}

.h-\[40vh\] {
  height: 40vh;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-14 {
  height: 3.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-32 {
  width: 8rem;
}

.flex-1 {
  flex: 1;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.basis-4\/5 {
  flex-basis: 80%;
}

.basis-4\/12 {
  flex-basis: 33.3333%;
}

.basis-1\/5 {
  flex-basis: 20%;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.basis-3\/12 {
  flex-basis: 25%;
}

.basis-5\/12 {
  flex-basis: 41.6667%;
}

.translate-y-32 {
  --tw-translate-y: 8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-\[fade-in_1s_ease-in-out\] {
  animation: fade-in 1s ease-in-out;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-\[zoom-in_1s_ease-in-out\] {
  animation: zoom-in 1s ease-in-out;
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.animate-\[slide-in-right_1s_ease-in-out\] {
  animation: slide-in-right 1s ease-in-out;
}

@keyframes grow-bubble-gum {
  0%, 100% {
    transform: translateY(8rem);
  }

  20% {
    transform: translateY(8rem)scale(1.5)rotate(-15deg);
  }

  50% {
    transform: translateY(8rem)scale(1);
  }
}

.animate-grow-bubble-gum {
  animation: grow-bubble-gum 5s cubic-bezier(.88, -.11, .58, 1) infinite;
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.animate-\[slide-in-left_1s_ease-in-out\] {
  animation: slide-in-left 1s ease-in-out;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#EF629F\] {
  --tw-gradient-from: #ef629f;
  --tw-gradient-to: #ef629f00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #a855f7;
  --tw-gradient-to: #a855f700;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#E38370\] {
  --tw-gradient-to: #e38370;
}

.to-pink-500 {
  --tw-gradient-to: #ec4899;
}

.bg-contain {
  background-size: contain;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-center {
  background-position: center;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-1 {
  padding: .25rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.font-fifindrel {
  font-family: Aka-AcidGR-Fifindrel, serif;
}

.font-cuttingEdge {
  font-family: Aka-AcidGR-CuttingEdge, serif;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-transparent {
  color: #0000;
}

.text-\[\#DA5686\] {
  --tw-text-opacity: 1;
  color: rgb(218 86 134 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-40 {
  opacity: .4;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.contrast-125 {
  --tw-contrast: contrast(1.25);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-\[\.1\] {
  --tw-contrast: contrast(.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hue-rotate-\[310deg\] {
  --tw-hue-rotate: hue-rotate(310deg);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

:root {
  --sss: #ddd;
}

.hover\:contrast-100:hover {
  --tw-contrast: contrast(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 768px) {
  .md\:h-1\/4 {
    height: 25%;
  }

  .md\:basis-8\/12 {
    flex-basis: 66.6667%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)) );
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)) );
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:leading-loose {
    line-height: 2;
  }
}

@media (min-width: 1024px) {
  .lg\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

/*# sourceMappingURL=index.158e4a74.css.map */
