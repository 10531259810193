/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&family=Pacifico&display=swap'); */

@font-face {
    font-family: 'Aka-AcidGR-Fifindrel';
    src: url('assets/fonts/Aka-AcidGR-Fifindrel.woff2') format('woff2'),
        url('assets/fonts/Aka-AcidGR-Fifindrel.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Aka-AcidGR-CuttingEdge';
    src: url('assets/fonts/Aka-AcidGR-CuttingEdge.woff2') format('woff2'),
        url('assets/fonts/Aka-AcidGR-CuttingEdge.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Aka-Acid-BrieLight';
    src: url('assets/fonts/Aka-Acid-BrieLight.woff2') format('woff2'),
        url('assets/fonts/Aka-Acid-BrieLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}