@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --sss: #ddd;
}

@layer base {
    html {
        font-family: 'Aka-Acid-BrieLight', serif;
        /* color: #37383F; */
    }

    ::selection {
        color: white;
        background: pink;
    }

    /* h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
    }
    a {
        @apply text-blue-600 underline;
    } */
}

@layer components {
    .colorful-top {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(242, 242, 242, 0.29) 49.55%, #FFFFFF 100%), linear-gradient(90deg, #F9DDF1 0%, #E5E1FF 18.87%, #DAEDFF 36.29%, #E2F4E3 51.66%, #EBF5D8 67.03%, #FAF2DA 80.04%, #FBE5D8 98.28%);
    }

    .colorful-bottom {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(242, 242, 242, 0.29) 49.55%, #FFFFFF 100%), linear-gradient(90deg, #FBE5D8 0%, #FAF2DA 9.71%, #EBF5D8 25.13%, #E2F4E3 33.61%, #DAEDFF 48.48%, #E5E1FF 66.68%, #F9DDF1 80.72%);
    }

    /* .btn-blue {
        @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded;
    } */
    
    /* [data-animation] {
        border: 1px solid red !important;
    } */
}

@layer utilities {
    .filter-none {
        filter: none;
    }

    .filter-grayscale {
        filter: grayscale(100%);
    }
}